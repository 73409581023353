<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap42Cheatsheet"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 42: Cheatsheet" image-name="cheating.jpg" image-alt="Cheatsheet"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="build-a-program" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> Build a program <a href="#build-a-program"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When your main file (that belongs to package main and have a main function) has the following relative path : <strong>/my/program/main.go</strong>. Open a terminal and type</p>
<pre v-highlightjs><code class="go" v-pre >$ go build -o binaryName /my/program/main.go</code></pre>
<p>The program will be compiled. A binary named <strong>binaryName</strong> will be created in the current directory (where you execute the command)</p>
<div id="slices" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Slices <a href="#slices"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A growable collection of elements of the same type.</p></li>
<li><p>Size is not known at compile time</p></li>
<li><p>Internally: a pointer to an underlying array</p></li>
<li><p>A good resource for playing with slices is the “Slice Tricks” page<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a></p></li>
</ul>
<div id="create-a-slice" class="anchor"></div>
<h2 data-number="2.1"><span class="header-section-number">2.1</span> Create a slice <a href="#create-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">make([]int,0)</code></span> : a slice of integers with a length 0 and a capacity of 0</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">make([]int,0,10)</code></span> : a slice of integers with a length 0 and a capacity of 10</p></li>
<li><p><strong>Slicing</strong> an existing array :</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >a := [4]int{1,2,3,4}
s = a[:3]
// s = [1,2]
// capacity = 4
// length = 2</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">s := []int{1,2,3,4}</code></span> create a slice and fill it directly</p>
<div id="add-elements-to-slice" class="anchor"></div>
<h2 data-number="2.2"><span class="header-section-number">2.2</span> Add elements to slice <a href="#add-elements-to-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">s = append(s,0)</code></span> : Add 0 to the slice s</p>
<div id="access-an-element-by-its-index" class="anchor"></div>
<h2 data-number="2.3"><span class="header-section-number">2.3</span> Access an element by its index <a href="#access-an-element-by-its-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >s[8]</code></pre>
<p>get the element of slice s at index 8. (be careful, the index starts at 0)</p>
<div id="copy" class="anchor"></div>
<h2 data-number="2.4"><span class="header-section-number">2.4</span> Copy <a href="#copy"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li>copy builtin allows copying data from (source) one slice to another (destination)</li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func copy(destination, source []Type) int</code></pre>
<ul>
<li>Destination then source (alphabetical order)</li>
</ul>
<div id="empty-a-slice" class="anchor"></div>
<h2 data-number="2.5"><span class="header-section-number">2.5</span> Empty a slice <a href="#empty-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >a := []int{1, 2, 3, 4, 5, 6, 7, 8, 9, 10}
a = a[:0]

// OR

a = nil</code></pre>
<div id="prepend-an-element-to-a-slice" class="anchor"></div>
<h2 data-number="2.6"><span class="header-section-number">2.6</span> Prepend an element to a slice <a href="#prepend-an-element-to-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >b := []int{2, 3, 4}
b = append([]int{1}, b...)</code></pre>
<div id="remove-an-element-at-index" class="anchor"></div>
<h2 data-number="2.7"><span class="header-section-number">2.7</span> Remove an element at index <a href="#remove-an-element-at-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >a = append(a[:i], a[i+1:]...)</code></pre>
<div id="find-an-element-in-a-slice" class="anchor"></div>
<h2 data-number="2.8"><span class="header-section-number">2.8</span> Find an element in a slice <a href="#find-an-element-in-a-slice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>You will have to iterate over it (with a for loop). Maybe use a map instead?</p>
<div id="put-an-element-at-index" class="anchor"></div>
<h2 data-number="2.9"><span class="header-section-number">2.9</span> Put an element at index <a href="#put-an-element-at-index"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >s = append(s, 0)
copy(s[i+1:], s[i:])
s[i] = x</code></pre>
<div id="go-modules" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Go modules <a href="#go-modules"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Initialize Go modules</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod init yourModulePath</code></pre>
<ul>
<li>Usually, the module path is an URL of a code-sharing website (GitHub, GitLab, bitbucket ...)</li>
</ul></li>
<li><p>Upgrade all dependencies to the <strong>latest</strong> version (<strong>minor versions and patches</strong>)</p>
<pre v-highlightjs><code class="go" v-pre >$ go get -u</code></pre></li>
<li><p>Upgrade a specific dependency (ex: gitlab.com/loir402/foo)</p>
<pre v-highlightjs><code class="go" v-pre >$ go get gitlab.com/loir402/foo</code></pre></li>
<li><p>Upgrade/Downgrade a specific dependency to a specific revision</p>
<pre v-highlightjs><code class="go" v-pre >$ $ go get module_path@X</code></pre>
<p>Where X can be :</p>
<ul>
<li><p>A commit hash</p>
<ul>
<li>Ex : b822ebd</li>
</ul></li>
<li><p>A version string</p>
<ul>
<li>Ex : v1.0.3</li>
</ul></li>
</ul></li>
<li><p>Cleanup your go.mod and go.sum file</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod tidy</code></pre></li>
<li><p>Print the dependency graph of your module</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod graph</code></pre></li>
<li><p>Create a vendor directory to store all your dependencies</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod vendor</code></pre></li>
<li><p>Check that the dependencies downloaded locally have not been altered</p>
<pre v-highlightjs><code class="go" v-pre >$ go mod verify</code></pre></li>
<li><p>List all dependencies along with their version</p>
<ul>
<li>This list is also known as the build list <b-link class="citation" data-cites="minimal-version-cox" href="#minimal-version-cox" >[@minimal-version-cox]</b-link></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >$ go list -m all</code></pre></li>
</ul>
<div id="goimports" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Goimports <a href="#goimports"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">goimports</code></span> is a command-line tool to automatically <strong>sort</strong> the imports of a source file : Here is an example of unsorted import declarations</p>
<pre v-highlightjs><code class="go" v-pre >//...
import (
    &quot;encoding/csv&quot;
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;math/rand&quot;
    &quot;maximilien-andile.com/errors/application/generator&quot;
    &quot;os&quot;
    &quot;time&quot;
)
//...</code></pre>
<p>Here is the sorted version :</p>
<pre v-highlightjs><code class="go" v-pre >import (
    &quot;encoding/csv&quot;
    &quot;fmt&quot;
    &quot;log&quot;
    &quot;math/rand&quot;
    &quot;os&quot;
    &quot;time&quot;

    &quot;maximilien-andile.com/errors/application/generator&quot;
)</code></pre>
<div id="installation" class="anchor"></div>
<h2 data-number="4.1"><span class="header-section-number">4.1</span> Installation <a href="#installation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Open a terminal and type the following command :</p>
<pre v-highlightjs><code class="go" v-pre >$ go get golang.org/x/tools/cmd/goimports</code></pre>
<p>The binary will be installed here : <span v-highlightjs><code class="go" v-pre style="display: inline">$GOPATH/bin/goimports</code></span>.</p>
<p>To make it available everywhere, make sure to add the folder <span v-highlightjs><code class="go" v-pre style="display: inline">$GOPATH/bin</code></span> to your PATH</p>
<div id="usage" class="anchor"></div>
<h2 data-number="4.2"><span class="header-section-number">4.2</span> Usage <a href="#usage"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The command to use is :</p>
<pre v-highlightjs><code class="go" v-pre >$ goimports -w file.go</code></pre>
<p>Where <span v-highlightjs><code class="go" v-pre style="display: inline">file.go</code></span> is the file that you want to treat</p>
<div id="unit-tests" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> Unit Tests <a href="#unit-tests"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="test-files" class="anchor"></div>
<h2 data-number="5.1"><span class="header-section-number">5.1</span> Test files <a href="#test-files"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Tests files are placed in the package directory</p></li>
<li><p>They have the _test.go suffix</p></li>
</ul>
<div id="test-function" class="anchor"></div>
<h2 data-number="5.2"><span class="header-section-number">5.2</span> Test Function <a href="#test-function"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Test functions are exported and have a specific name</p>
<figure>
<b-img :src="require('@/assets/images/test_function_name.png')" alt="Test function signature"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Test function signature</figcaption>
</figure>
<div id="table-test" class="anchor"></div>
<h2 data-number="5.3"><span class="header-section-number">5.3</span> Table Test <a href="#table-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Here is an example table test :</p>
<pre v-highlightjs><code class="go" v-pre >func Test_totalPrice(t *testing.T) {
    type parameters struct {
        nights  uint
        rate    uint
        cityTax uint
    }
    type testCase struct {
        name string
        args parameters
        want uint
    }
    tests := []testCase{
        {
            name: &quot;test 0 nights&quot;,
            args: parameters{nights: 0, rate: 150, cityTax: 12},
            want: 0,
        },
        {
            name: &quot;test 1 nights&quot;,
            args: parameters{nights: 1, rate: 100, cityTax: 12},
            want: 112,
        },
        {
            name: &quot;test 2 nights&quot;,
            args: parameters{nights: 2, rate: 100, cityTax: 12},
            want: 224,
        },
    }
    for _, tt := range tests {
        t.Run(tt.name, func(t *testing.T) {
            time.Sleep(time.Second*1)
            t.Parallel()
            if got := totalPrice(tt.args.nights, tt.args.rate, tt.args.cityTax); got != tt.want {
                t.Errorf(&quot;totalPrice() = %v, want %v&quot;, got, tt.want)
            }
        })
    }
}</code></pre>
<div id="commands" class="anchor"></div>
<h2 data-number="5.4"><span class="header-section-number">5.4</span> Commands <a href="#commands"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Run all tests in a project</p>
<pre v-highlightjs><code class="go" v-pre >$ go test ./...</code></pre></li>
<li><p>Run tests of a specific package</p>
<ul>
<li><p>Into the package directory :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test</code></pre></li>
<li><p>Outside the package directory :</p>
<pre v-highlightjs><code class="go" v-pre >$ go test modulepath/packagename</code></pre></li>
</ul></li>
</ul>
<div id="naming-conventions" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Naming Conventions <a href="#naming-conventions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="module-path" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> Module path <a href="#module-path"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>The module path is generally an URL pointing to a code-sharing website.</p>
<p>Ex : <span v-highlightjs><code class="go" v-pre style="display: inline">gitlab.com/loir402/bluesodium</code></span></p>
<p>The module path is written on the <strong>go.mod</strong> file :</p>
<pre v-highlightjs><code class="go" v-pre >module gitlab.com/loir402/bluesodium

go 1.15</code></pre>
<p>Others will use the module path to import your module (if you have access to the repository) via, for instance, the go get command</p>
<pre v-highlightjs><code class="go" v-pre >go get gitlab.com/loir402/bluesodium</code></pre>
<p>The last part of the URL should match the name of the principal <strong>package</strong> of your module (that will generally be placed at the root of the project directory).</p>
<pre v-highlightjs><code class="go" v-pre >package main

import &quot;gitlab.com/loir402/bluesodium&quot;

func main() {
    bluesodium.Color()
}</code></pre>
<div id="package-name" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> Package name <a href="#package-name"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>short</p></li>
<li><p>simple</p></li>
<li><p>concise</p></li>
<li><p>one word is better than two or more</p></li>
</ul>
<div id="the-error-interface" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> The error interface <a href="#the-error-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is the definition of the <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span> interface (in the standard library) :</p>
<pre v-highlightjs><code class="go" v-pre >package builtin
//...
type error interface {
    Error() string
}</code></pre>
<p>Here is an example implementation of this interface :</p>
<pre v-highlightjs><code class="go" v-pre >// ReadingError is a custom type
type ReadingError struct {
}

// ReadingError implments error interface
func (e *ReadingError) Error() string {
    return &quot;error&quot;
}</code></pre>
<div id="type-assertion" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Type assertion <a href="#type-assertion"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is the syntax of a type assertion.</p>
<pre v-highlightjs><code class="go" v-pre >x.(T)</code></pre>
<ul>
<li>the value <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> has a <strong>type interface</strong></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is a type. It might be a concrete type or a type interface.</p>
<div id="when-t-is-a-concrete-type" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> When T is a concrete type <a href="#when-t-is-a-concrete-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<pre v-highlightjs><code class="go" v-pre >func doSomething() error {
    //...
}

err := doSomething()</code></pre>
<p>In this program, we define a function <span v-highlightjs><code class="go" v-pre style="display: inline">doSomething</code></span> which returns an element of type interface <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span>. We execute this function, and we assign the return value to a new variable<span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span>.</p>
<p>We just know that <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span> is of type interface <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span>. We want to check that the dynamic type of <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">*ReadingError</code></span>. We can use a type assertion :</p>
<pre v-highlightjs><code class="go" v-pre >// type assertion
v, ok := err.(*ReadingError)

if ok {
    fmt.Println(&quot;err is of type *ReadingError&quot;)
}</code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">err.(*ReadingError)</code></span> returns two values :</p>
<ul>
<li><p>When err is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*ReadingError</code></span>. Assertion holds</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">v</code></span> is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*ReadingError</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is equal to true<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a></p></li>
<li><p>Otherwise</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">v</code></span> is the zero value of the type <span v-highlightjs><code class="go" v-pre style="display: inline">*ReadingError</code></span>. In this context, it’s equal to <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is equal to false<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a></p></li>
</ul>
<div id="t-must-implement-the-interface-type-of-x" class="anchor"></div>
<h3 data-number="8.1.1"><span class="header-section-number">8.1.1</span> T must implement the interface type of x! <a href="#t-must-implement-the-interface-type-of-x"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The type assertion <span v-highlightjs><code class="go" v-pre style="display: inline">err.(*ReadingError)</code></span> is legal in this context. The type <span v-highlightjs><code class="go" v-pre style="display: inline">*ReadingError</code></span> implements the interface <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span> and the value <span v-highlightjs><code class="go" v-pre style="display: inline">err</code></span> is of interface type <span v-highlightjs><code class="go" v-pre style="display: inline">error</code></span>.</p>
<div id="when-t-is-an-interface-type" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> When <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is an interface type <a href="#when-t-is-an-interface-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s take an example with two interfaces <span v-highlightjs><code class="go" v-pre style="display: inline">Adder</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >type Adder interface {
    Add(a, b int) int
}

type Divider interface {
    Divide(a, b int) float64
}</code></pre>
<p>Then we define a new type MyAdder which implements <span v-highlightjs><code class="go" v-pre style="display: inline">Adder</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span>.</p>
<pre v-highlightjs><code class="go" v-pre >type MyAdder struct {
}

func (t *MyAdder) Add(a, b int) int {
    return a + b
}
func (t *MyAdder) Divide(a, b int) float64 {
    return float64(a / b)
}</code></pre>
<p>The function <span v-highlightjs><code class="go" v-pre style="display: inline">foo</code></span> returns an <span v-highlightjs><code class="go" v-pre style="display: inline">Adder</code></span> interface type.</p>
<pre v-highlightjs><code class="go" v-pre >func foo() Adder {
    return &amp;MyAdder{}
}</code></pre>
<p>Let’s check if the return value of <span v-highlightjs><code class="go" v-pre style="display: inline">foo</code></span> also implements the <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span> interface :</p>
<pre v-highlightjs><code class="go" v-pre >func main() {
    x := foo()
    if v, ok := x.(Divider); ok {
        fmt.Println(&quot;x implements also interface Divider&quot;,v)
    }
}</code></pre>
<p>The syntax is identical. The type assertion will also check if <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> is not <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span>.</p>
<ul>
<li><p>When <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> implements <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span> and is not nil :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">v</code></span> implements <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span></p></li>
<li><p>Otherwise</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">v</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">nil</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">ok</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">false</code></span></p></li>
</ul>
<div id="a-type-assertion-that-may-panic" class="anchor"></div>
<h2 data-number="8.3"><span class="header-section-number">8.3</span> A type assertion that may panic <a href="#a-type-assertion-that-may-panic"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you use the syntax :</p>
<pre v-highlightjs><code class="go" v-pre >v, ok := x.(Divider)</code></pre>
<p>your program will not panic if <span v-highlightjs><code class="go" v-pre style="display: inline">x</code></span> is not of type <span v-highlightjs><code class="go" v-pre style="display: inline">Divider</code></span>. Whereas the following syntax might cause a panic :</p>
<pre v-highlightjs><code class="go" v-pre >v := x.(Multiplier)
fmt.Printf(&quot;%v&quot;, v)</code></pre>
<p>Here is the standard output :</p>
<pre v-highlightjs><code class="go" v-pre >panic: interface conversion: *main.MyAdder is not main.Multiplier: missing method Multiply

goroutine 1 [running]:
main.main()
        /path/to/main.go:38 +0x100

Process finished with exit code 2</code></pre>
<div id="environment-variables" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Environment variables <a href="#environment-variables"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>To check if an environment variable exists, you can use the <span v-highlightjs><code class="go" v-pre style="display: inline">os</code></span> package :</p>
<pre v-highlightjs><code class="go" v-pre >port, found := os.LookupEnv(&quot;DB_PORT&quot;)
if !found {
    log.Fatal(&quot;impossible to start up, DB_PORT env var is mandatory&quot;)
}</code></pre>
<p>LookupEnv allows you to check if an env exists before using it.</p>
<div id="useful-links" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Useful links <a href="#useful-links"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li>The Go developer mailing list: https://groups.google.com/g/golang-dev</li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>See https://github.com/golang/go/wiki/SliceTricks<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>It’s an untyped boolean value<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>It’s an untyped boolean value<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap42Cheatsheet"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap41DesignRecommendations'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Design Recommendations</small></p>
								</router-link>
							</b-col>
						
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Cheatsheet - Practical Go Lessons"
const description = "Go cheatsheet"

export default {
  name: "Chap42Cheatsheet",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
